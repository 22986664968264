//fonts import
@font-face {
    font-family: "Futura";
    src: url("./fonts/Futura-Hv-BT-Heavy.woff2") format("woff2"),
         url("./fonts/Futura-Hv-BT-Heavy.woff") format("woff");
  }

  @font-face {
    font-family: "ITCSouvenir";
    src: url("./fonts/ITC-Souvenir-W01-Demi.woff2") format("woff2"),
         url("./fonts/ITC-Souvenir-W01-Demi.woff") format("woff");
  }

  
// color scheme
$yellow:#FFD400;
$violette:#5F506B;
$white:#fdf9f9;
$pink:#E574BC;


// font styles
$display:'Futura';
$primary:'ITCSouvenir';

//font sizes
$display-size:20pt;
$primary-size:14pt;
$mobile-size:10pt;

body {
    background-color: $white;
    width: 100%;
    margin: 0px;
    position:relative;
  }

div{
    font-family: $primary;
    font-size: $primary-size ;
    padding: 0;
    margin: 0;
}

@media (max-width:629px) {
    div {
        font-size: $mobile-size ;

    }
    p {
        font-size: $mobile-size ;

    }
  }
