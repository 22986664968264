@import "./../../styles.scss";

.footer-container {
  position: absolute;
  bottom: 0;
  height: 7em;
  width: 100%;
  background-color: $violette;
}
.footer-div {
  height: 5em;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  color: $white;
  font-size: $primary-size;

}

.footer-social-links {
  width: 100%;
  font-family: $primary;
  margin-left: 0;
  padding-left: 0.2em;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.footer-social-link-item {
  list-style-type: none;
  text-decoration: none;
  margin-left: 0;
  padding: 25px;
  padding-bottom: 1em;
}
.footer-social-link {
  text-decoration: none;
}
.footer-social-link:visited {
  color: inherit;
}
.footer-div-logo {
  position: absolute;
  right: 20px;
}
.footer-logo-img {
  margin-top: 5%;
  width: 80px;
}

.footer-text {
  position: absolute;
  bottom: 0;
  left: 20px;
  font-size: $mobile-size;
  color: $white;
}
.footer-email {
  font-style: $primary;
  font-size: $primary-size;
  text-decoration: none;
  color: $white;
}

.footer-email:visited {
  color: inherit;
}

.footer-email:hover {
  transition: all 0.5s ease-in-out;
  color: $white;
}

@media (max-width:629px) {
  
.footer-social-link-item {
  padding: 5px;
}
  .footer-icon{
    height: 30px;
  }
.footer-logo-img {
  display: none; 
}
.footer-text {
  font-size:6pt;
}
}