@import './../../styles.scss';

.blog-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:1000px;
}
.blog-header{
    display: flex;
    flex-direction: column;
}