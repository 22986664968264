@import './../../styles.scss';

.home-container{
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $violette;
}

.home-text{
    font-size: 18pt;
}

a.home-link{ font-size: 20pt;
    text-decoration: none;
    color: $pink;
}
a.home-link:hover{
    color: $violette ;
    transition: all .5s ease-in-out;
}

span{
    padding-left:10px; 
    padding-right:10px; 
}

@media (max-width:629px) {
    
    .home-text {
        font-size: $mobile-size ;

    }
    a.home-link{
        font-size: $mobile-size ;
    }
  }
