
@import "./../../styles.scss";


.slider-main {
    display: flex;
    align-items: center;
    width: 60%;
    height: 70vh;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    margin: auto;
    border-radius:25px;
    border-style: solid;

  }
  
  .slide-item {
    min-width: 100%;
    height: 80vh;
    margin-right: 10%;
    -webkit-margin-end: 21%;
            margin-inline-end: 10%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
  }
  
  #goLeft, #goRight {
    position: absolute;
    top: 50%;
    padding: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 10%;
    height: 82vh;
    background: none;
    border: none;
    color: $violette;
  }
  
  #goLeft:focus, #goRight:focus {
    text-decoration: none;
    outline: none;
    border: none;
  }
  
  #goLeft:hover, #goRight:hover {
    cursor: pointer;
    font-size: 12pt;
  }
  
  #goLeft {
    left: 0;
  }
  
  #goRight {
    right: 0;
  }
  /*# sourceMappingURL=slider.css.map */