@import './../../styles.scss';

.about-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:1000px;
}

.about-header{
width: 40%;
}

.about-text{
    width: 40%;
    color: $pink;
}