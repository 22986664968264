@import "../styles.scss";

.project-main {
  display: flex;
  height: 500px;
  margin-left: 10%;
}

.game-icon {
  width: 25%;
  height: 80px;
  border-radius: 25%;
  background-color: $violette;
  padding: 5px;
}

.color-overlay {
  background: $violette;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  margin: auto;
  display: flex;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  &:hover {
    opacity: 0.9;
  }
}
.project-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 50%;
}
.project-link {
  cursor: pointer;
  text-decoration: none;
  font-family: $primary;
  font-size: 14pt;
  margin: 15px;
  -webkit-transition: all 500ms ease;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    font-size: 18pt;

    color: $yellow;
    letter-spacing: 0.2em;
  }
}
.game-card-left {
  padding-top: 100px;
  padding-left: 0;
  width: 50%;
}
.game-header {
  font-family: $display;
  display: flex;
  flex-direction: row;
}
.game-title {
  margin: 5%;
}
.game-text{
  padding-top: 10%;
  font-size: 12pt;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .project-link {
    font-size: 10pt;
    &:hover {
      font-size: 14pt;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 736px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 737px) and (max-width: 992px) {
}
