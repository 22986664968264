@import "./../../styles.scss";


.nav-container {
  width: 100%;
  height: 200px;
  text-align: initial;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  align-items: flex-end;
  background-color: $white;
}

.nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  list-style-type: none;
  margin-right: 10%;
  margin-top: 2%;
  float: right;
  vertical-align: text-bottom;
}


.nav-logo-img {
  padding: 1%;
  position: absolute;
  vertical-align: top;
  display: flex;
}

.nav-logo-img {
  position: absolute;
  left: 30px;
  vertical-align: top;
  margin-top: 0px;
  height: 100px;
}

.nav-link {
  color: $violette;
  text-decoration: none;
  font-size: $display-size;
  font-family: $display;
  padding: 5%;
}

.nav-link-dark{
  color: $violette;
  text-decoration: none;
  font-size: $display-size;
  font-family: $display;
  padding: 5%;
}

.nav-link:visited {
  color: $violette;
}
.nav-link-dark:visited {
  color: $violette;
}
.nav-link:hover,
.nav-link:active {
  transition: all 0.5s ease-in-out;
  color: $pink;
}
.nav-link-dark:hover,
.nav-link-dark:active {
  transition: all 0.5s ease-in-out;
  color: $pink;
}
@media (max-width:629px) {
  .nav-link {
    font-size: $mobile-size;
    padding: 5%;
  }
  
  img.nav-logo-img {
    display: flex;
    justify-content: center;
    height: 30px;
  }
}